import {v4 as uuidv4} from 'uuid';

const getHeaders = () => {
    const correlationId = uuidv4();

    return {
        'X-Correlation-Id': correlationId,
        'X-Service-Name': process.env.SERVICE_NAME,
        'X-Service-Instance': process.env.INSTANCE,
        'X-Service-Version': process.env.VERSION,
    };
}

export default getHeaders;